import * as types from '../constants/actionTypes';
import Utils from 'utils';
import localforage from 'localforage';
import { notification } from 'antd';

export function loadSettings() {
  return async (dispatch, getState) => {
    // let token =
    localforage.getItem('token').then(resp => {
      if (resp) {
        Utils.setAuthorizationToken(resp);
        dispatch({
          type: types.LOAD_SETTINGS_PENDING,
        });
        Utils.request({
          url: '/api/settings/parameters',
          method: 'GET',
        })
          .then(response => {
            dispatch({
              type: types.LOAD_SETTINGS_SUCCESS,
              data: response.data,
            });
          })
          .catch(err => {
            dispatch({
              type: 'LOAD_SETTINGS_REJECTED',
            });
            notification.open({
              message: 'Erro!',
              description: 'Ocorreu um erro inesperado!',
            });
          });
      }
    });
  };
}

export function saveSettings() {
  return async (dispatch, getState) => {
    // let token =
    localforage.getItem('token').then(resp => {
      if (resp) {
        dispatch({
          type: types.SAVE_SETTINGS_PENDING,
        });
        Utils.setAuthorizationToken(resp);
        Utils.request({
          url: '/api/settings/parameters',
          method: 'POST',
          data: getState().config,
        })
          .then(response => {
            dispatch({
              type: types.SAVE_SETTINGS_SUCCESS,
            });
            notification.open({
              message: 'Sucesso',
              description: 'Configurações salvas com sucesso!',
            });
          })
          .catch(() => {
            dispatch({
              type: 'SAVE_SETTINGS_REJECTED',
            });
            notification.open({
              message: 'Erro!',
              description: 'Ocorreu um erro inesperado!',
            });
          });
      }
    });
  };
}

export function changeCrawlerMinValue(value) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'CHANGE_CRAWLER_MIN_VALUE',
      value: value,
    });
  };
}

export function changeNotificationDaysLeft(value) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'CHANGE_NOTIFICATION_DAYS_LEFT',
      value: value,
    });
  };
}

export function changeReceiveEmail(value) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'CHANGE_RECEIVE_EMAIL',
      value: value,
    });
  };
}

export function changeParam(k, v) {
  return async dispatch => {
    dispatch({
      type: types.CHANGE_PARAM,
      k,
      v,
    });
  };
}

export function crecispSession(key = 'broker') {
  return async dispatch => {
    try {
      dispatch({ type: types.CRECISP_LOADING_SESSION, key });
      await Utils.request({
        url: `/api/settings/crecisp_session/${key}`,
      }).then(response => {
        dispatch({
          type: types.CRECISP_SESSION,
          key,
          data: response.data.data,
        });
      });
      dispatch({ type: types.CRECISP_FULFILLED_SESSION, key });
    } catch (e) {}
  };
}

export function crecispRunRealestate() {
  return async dispatch => {
    dispatch({
      types: types.CRECISP_RUN_PENDING,
    });
    try {
      await Utils.request({
        url: '/api/run_crecisp_realestate',
        method: 'POST',
      }).then(() => {
        dispatch({
          type: types.CRECISP_RUN_SUCCESS,
        });
      });
    } catch (e) {
      dispatch({
        type: types.CRECISP_RUN_REJECTED,
      });
    }
  };
}
