import * as types from '../constants/actionTypes';
import Utils from 'utils';

export function getComparative(hash, page, pageSize, showLoading = false) {
  return async dispatch => {
    let response = {};
    try {
      if (showLoading) {
        dispatch({ type: types.GET_COMPARATIVE_PENDING });
      }
      response = await Utils.request({
        url: '/api/comparative',
        method: 'GET',
        params: {
          token: Utils.token,
          hash,
          page,
          pageSize,
        },
      });
      dispatch({ type: types.GET_COMPARATIVE_SUCCESS, data: response.data });
      dispatch({ type: types.COMPARATIVE_RESET_FILTERS });
      return response.data;
    } catch (e) {
      dispatch({ type: types.GET_COMPARATIVE_REJECTED, data: e.response.data });
      return e.response.data;
    }
  };
}

export function getComparativeHistory(showLoading = false) {
  return async dispatch => {
    try {
      if (showLoading) {
        dispatch({ type: types.GET_COMPARATIVE_HISTORY_PENDING });
      }
      const response = await Utils.request({
        url: '/api/comparative-history',
        method: 'GET',
        params: {
          token: Utils.token,
        },
      });
      dispatch({ type: types.GET_COMPARATIVE_HISTORY_SUCCESS, data: response.data });
    } catch (e) {
      dispatch({ type: types.GET_COMPARATIVE_HISTORY_REJECTED });
    }
  };
}

export function postComparative({
  search,
  force_reload = false,
  minPrice,
  maxPrice,
  minArea,
  maxArea,
  distance = [0, 200],
  property_type,
  qtt = 200,
  use_street,
  state,
  city,
  district,
} = {}) {
  return async dispatch => {
    try {
      dispatch({ type: types.GET_COMPARATIVE_PENDING });
      let maxDistance = distance && distance.length > 0 ? distance[1] : null;
      // When maxDistance is equal to the maximum removes constraint
      maxDistance = maxDistance >= 1500 ? null : maxDistance;
      let limit = qtt || null;
      // When limit is equal to the maximum removes constraint
      limit = limit >= 1500 ? null : limit;
      const response = await Utils.request({
        url: '/api/comparative',
        method: 'POST',
        data: {
          search,
          force_reload,
          minPrice,
          maxPrice,
          minArea,
          maxArea,
          minDistance: distance && distance.length > 0 ? distance[0] : null,
          maxDistance,
          property_type,
          limit,
          use_street,
          state,
          city,
          district,
        },
      });
      dispatch({ type: types.POST_COMPARATIVE_SUCCESS, data: response.data });
    } catch (e) {
      dispatch({ type: types.GET_COMPARATIVE_REJECTED, data: e.response.data });
    }
  };
}

export function setFilters(filters) {
  return async dispatch => {
    dispatch({ type: types.COMPARATIVE_SET_FILTERS, filters });
  };
}

export function changePage(page, pageSize) {
  return async dispatch => {
    dispatch({ type: types.COMPARATIVE_CHANGE_PAGE, page, pageSize });
  };
}

export function getAuction(id) {
  return async dispatch => {
    let response = {};
    try {
      dispatch({ type: types.GET_COMPARATIVE_PENDING });
      response = await Utils.request({
        url: '/api/auction',
        method: 'GET',
        params: {
          token: Utils.token,
          id,
        },
      });
      dispatch({ type: types.GET_AUCTION_SUCCESS, data: response.data });
      return response.data;
    } catch (e) {
      dispatch({ type: types.GET_AUCTION_REJECT, data: e.response.data });
      return e.response.data;
    }
  };
}

export function compareWithAuction(auction) {
  return async dispatch => {
    dispatch({ type: types.GET_AUCTION_SUCCESS, data: auction });
  };
}

export function reloadComparative(id) {
  return async dispatch => {
    try {
      await Utils.request({
        url: '/api/comparative-reload',
        method: 'POST',
        data: { id },
      });
    } catch (e) {
      console.error(e);
    }
  };
}
