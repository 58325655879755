import React from 'react';
import Utils from 'utils';
import { Icon, Popover, Row, Col } from 'antd';
import moment from 'moment';

const getDueDate = auction => {
  if (auction.due_date1 && auction.due_date2) {
    if (moment(auction.due_date1) < moment()) {
      return (
        <div>
          <span>{`Segundo leilão termina em: ${moment(auction.due_date2).format(
            'DD/MM/YYYY [às] HH:mm'
          )}`}</span>
        </div>
      );
    } else {
      return (
        <div>
          <span>{`Primeiro leilão termina em: ${moment(auction.due_date1).format(
            'DD/MM/YYYY [às] HH:mm'
          )}`}</span>
        </div>
      );
    }
  } else {
    if (auction.due_date1) {
      return (
        <div>
          <span>{`Leilão termina em: ${moment(auction.due_date1).format(
            'DD/MM/YYYY [às] HH:mm'
          )}`}</span>
        </div>
      );
    } else {
      return (
        <div>
          <span>Sem data de término</span>
        </div>
      );
    }
  }
};

export const AuctionCardHeader = ({ auction = {}, onFavorite }) => {
  const hasPassed = moment(auction.due_date1) < moment();
  return (
    <div style={{ minHeight: '100px' }}>
      <Row>
        <Col span={12}>
          <div className="card__title">
            <Popover
              content={<div>{auction.is_favorite ? 'Remover favorito' : 'Favoritar'}</div>}
              title=""
            >
              <Icon
                type={auction.is_favorite ? 'star' : 'star-o'}
                theme={auction.is_favorite ? 'filled' : 'outlined'}
                style={{
                  color: auction.is_favorite ? 'yellow' : 'black',
                  float: 'left',
                  cursor: 'pointer',
                  marginTop: '4px',
                }}
                onClick={onFavorite}
              />
            </Popover>
            <h4 style={{ marginLeft: '25px' }}>{auction.title}</h4>
            {auction.city && auction.state && <span>{`${auction.city} - ${auction.state}`}</span>}
            {getDueDate(auction)}
            {auction.comitent && <span>{`${auction.comitent}`}</span>}
            {auction.notes && <span>{`${auction.notes}`}</span>}
          </div>
        </Col>
        <Col span={12}>
          <div style={{ float: 'right' }}>
            {auction.due_date1 && auction.due_date2 ? (
              <div>
                <div style={{ textDecoration: hasPassed ? 'line-through' : 'none' }}>
                  <span>
                    1º Leilão:{' '}
                    <b style={{ fontSize: '20px' }}>{Utils.formatters.currency(auction.price1)}</b>
                  </span>
                </div>
                <div>
                  <span>
                    2º Leilão:{' '}
                    <b style={{ fontSize: '20px' }}>{Utils.formatters.currency(auction.price2)}</b>
                  </span>
                </div>
              </div>
            ) : (
              <div>
                <span>
                  1º Leilão:{' '}
                  <b style={{ fontSize: '20px' }}>{Utils.formatters.currency(auction.price1)}</b>
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
