import React from 'react';
import { Alert, Form, Icon, Input, Button, Modal } from 'antd';
import { withRouter, Redirect } from "react-router-dom";
import { login, registerUser, openModalSignUp, closeModalSignUp } from 'actions/authActions';
import { connect } from 'react-redux';
const FormItem = Form.Item;

class NormalLoginForm extends React.Component {

  state = {
    data: {
      username: '',
      password: '',
      confirmPassword: '',
      email: '',
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.login(values);
      }
    });
  }

  showSignModal = () => {
    this.props.openModalSignUp();
  }

  handleCancel = () => {
    this.props.closeModalSignUp();
  }

  handleRegistration = () => {
    const { data } = this.state;
    this.props.registerUser(data);
  }

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
      errorConfirmation: '',
    });
  }

  render() {
    if (this.props.isAuthenticated && this.props.authToken) {
      return <Redirect to={'/app/auctions'} />;
    }

    const { getFieldDecorator } = this.props.form;
    const { data } = this.state;
    return (
      <section className="form-v1-container" style={{height: "100vh"}}>
        <h2>Login</h2>
        <p className="lead">Seja bem vindo! Faça o login com a conta</p>
        {this.props.errorMsg &&
          <Alert
            message="Erro!"
            description={this.props.errorMsg}
            type="error"
          />
        }
        <hr/>
        <Form onSubmit={this.handleSubmit} className="form-v1">
          <FormItem>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: 'Por favor, coloque seu nome de usuário!' }],
            })(
              <Input size="large" prefix={<Icon type="user" style={{ fontSize: 13 }} />} placeholder="Usuário" />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Por favor, coloque sua senha!' }],
            })(
              <Input size="large" prefix={<Icon type="lock" style={{ fontSize: 13 }} />} type="password" placeholder="Senha" />
            )}
          </FormItem>
          <FormItem>
            <Button type="primary" htmlType="submit" className="btn-cta btn-block">
              Log in
            </Button>
          </FormItem>
        </Form>
        <div className="divider divider-with-content my-4"><span className="divider-inner-content">OU</span></div>
        <Button type="primary" htmlType="submit" className="btn-cta btn-block" onClick={this.showSignModal}>
          Cadastre-se
        </Button>
        <Modal title="Cadastrar"
          visible={this.props.modalSignUp}
          onOk={this.handleRegistration}
          confirmLoading={false}
          onCancel={this.handleCancel}
        >
          {this.props.signUpError &&
            <Alert
              message="Erro!"
              description={this.props.signUpError}
              type="error"
            />
          }
          <Form onSubmit={this.handleRegistration} className="form-v1" style={{marginTop: "10px"}}>
            <FormItem>
              <Input size="large" prefix={<Icon type="user" style={{ fontSize: 13 }} />} name="username"
                onChange={this.onChange} value={data.username} placeholder="Usuário" />
            </FormItem>
            <FormItem>
              <Input size="large" prefix={<Icon type="lock" style={{ fontSize: 13 }} />} name="password"
                onChange={this.onChange} value={data.password} type="password" placeholder="Senha" />
            </FormItem>
            <FormItem>
              <Input size="large" prefix={<Icon type="lock" style={{ fontSize: 13 }} />} name="confirmPassword"
                onChange={this.onChange} value={data.confirmPassword} type="password" placeholder="Confirmação de Senha" />
            </FormItem>
            <FormItem>
              <Input size="large" prefix={<Icon type="mail" style={{ fontSize: 13 }} />} name="email"
                onChange={this.onChange} value={data.email} type="email" placeholder="Email" />
            </FormItem>
          </Form>
        </Modal>
      </section>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(withRouter(NormalLoginForm));


const mapStateToProps = state => ({
  authToken: state.auth.authToken,
  isAuthenticated: state.auth.isAuthenticated,
  errorMsg: state.auth.errorMsg,
  signUpError: state.auth.signUp.error,
  modalSignUp: state.auth.signUp.modal,
});

const mapDispatchToProps = dispatch => ({
  login: (values) => {
    dispatch(login(values));
  },
  registerUser: (data) => {
    dispatch(registerUser(data));
  },
  openModalSignUp: () => {
    dispatch(openModalSignUp());
  },
  closeModalSignUp: () => {
    dispatch(closeModalSignUp());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedNormalLoginForm);
