import React from 'react';
import Utils from 'utils';
import { Input, Form, Row, Select, Col, Button, Slider, Switch, Collapse } from 'antd';
import { setFilters, resetFilters } from '../../../actions/filterPagActions';
import { connect } from 'react-redux';
const Option = Select.Option;
const FormItem = Form.Item;

const optionList = it =>
  it ? (
    <Option key={it} value={it}>
      {it}
    </Option>
  ) : null;

const defaultFilterOption = (input, option) =>
  option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;

const getFilterRange = (filters, key, d, labelBefore, labelAfter) =>
  `${labelBefore} de ${filters && filters[key] != null ? filters[key][0] : d[0]} a ${
    filters && filters[key] != null ? filters[key][1] : d[1]
  } ${labelAfter}`;

const checkItemInArray = (arr, key, it) =>
  arr[key] === undefined ||
  !arr[key].length ||
  arr[key].find(s => it[key] && Utils.normalizaString(it[key]) === Utils.normalizaString(s));

class AdvancedFilterBase extends React.Component {
  initialState = { states: [], cities: [], districts: [], search: '', filters: {} };

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.dDistance = [0, 200];
    this.dQtt = [10, 50];
  }

  getFilters = () => ({ ...this.props.filters, ...this.state.filters });

  onTermChange = e => this.setState({ search: e.target.value });

  componentDidMount() {
    const filters = this.getFilters();
    const addresses = this.props.addresses;

    if (addresses != null && filters != null) {
      this.updateAddresses(addresses, filters);
    }
  }

  componentDidUpdate(prev) {
    const filters = this.getFilters();
    const prevFilters = this.getFilters(prev);
    const addresses = this.props.addresses;
    if (
      prev.addresses !== addresses ||
      (filters !== null &&
        prevFilters != null &&
        (prevFilters.state !== filters.state || prevFilters.city !== filters.city))
    ) {
      this.updateAddresses(addresses, filters);
    }

    if (filters != null && prevFilters !== filters) {
      this.updateFilters(filters);
    }
  }

  updateAddresses(addresses, filters) {
    let states = [];
    let cities = [];
    let districts = [];
    (addresses || []).forEach((it, idx) => {
      states.push(it.state);
      if (filters.state == null || checkItemInArray(filters, 'state', it)) {
        cities.push(it.city);
        if (filters.city == null || filters.city.length <= 0) {
          districts.push(it.district);
        }
      }
      if (
        (filters.city == null && filters.state == null) ||
        (filters.city != null && checkItemInArray(filters, 'city', it))
      ) {
        districts.push(it.district);
      }
    });

    this.setState({
      states: Utils.normalize('state_check', states),
      cities: Utils.normalize('city', cities),
      districts: Utils.normalize('district', districts),
    });
  }

  updateFilters(filters) {
    if (filters.minDistance) {
      this.dDistance[0] = filters.minDistance;
    }
    if (filters.maxDistance) {
      this.dDistance[1] = filters.maxDistance;
    }
    if (filters.minqtt) {
      this.dQtt[0] = filters.minqtt;
    }
    if (filters.limit) {
      this.dQtt[1] = filters.limit;
    }
  }

  handleFilter = key => value => {
    const filters = this.getFilters();
    const addresses = this.props.addresses;
    let temp;
    let f = { ...filters };
    switch (key) {
      case 'city':
        f.city = value;
        if (
          addresses.findIndex(item => {
            return item.city === value && f.district === item.district;
          }) === -1
        ) {
          f.district = null;
        }
        break;
      case 'state':
        f.state = value;
        if (
          addresses.findIndex(item => {
            return value.find(s => s === item.state) && f.city === item.city;
          }) === -1
        ) {
          f.city = null;
        }
        if (
          addresses.findIndex(item => {
            return value.find(s => s === item.city) && f.district === item.district;
          }) === -1
        ) {
          f.district = null;
        }
        break;
      case 'minPrice':
      case 'maxPrice':
      case 'minArea':
      case 'maxArea':
      case 'minValue':
      case 'maxValue':
        temp = value.target.value;
        temp = temp.replace(/[^0-9]/g, '');
        f[key] = Number(temp);
        break;
      default:
        f[key] = value;
    }
    this.setState({ filters: f }, () => {
      if (key === 'city' || key === 'state') {
        this.updateAddresses(addresses, f);
      }
    });
  };

  handleReset = async () => {
    const { resetFilters, datakey, pageSize } = this.props;
    await resetFilters(datakey, 1, pageSize);
    this.setState(this.initialState);
  };

  handleSearchTerm = async term =>
    await this.props.setFilters(
      this.props.datakey,
      { ...this.props.filters, search: term },
      1,
      this.props.pageSize
    );

  render() {
    const { filterDef = [], searchPlaceholder, datakey, pageSize } = this.props;
    const filters = this.getFilters();
    const customPanelStyle = { borderRadius: 4, border: 0 };

    let fields = filterDef.reduce(
      (acc, it, idx) => {
        let input;
        switch (it.type) {
          case 'select':
            let collection = [];
            if (it.key === 'state') {
              collection = this.state.states;
            } else if (it.key === 'city') {
              collection = this.state.cities;
            } else if (it.key === 'district') {
              collection = this.state.districts;
            } else {
              collection = this.props[it.collection];
            }
            input = (
              <FormItem label={it.label}>
                <Select
                  allowClear={it.allowClear || true}
                  showSearch={it.showSearch || true}
                  mode={it.mode || 'tags'}
                  style={{ width: '100%', maxWidth: it.maxWidth || 'auto' }}
                  value={(filters && filters[it.key]) || []}
                  placeholder={it.placeholder}
                  optionFilterProp="children"
                  onChange={this.handleFilter(it.key)}
                  filterOption={defaultFilterOption}
                  disabled={
                    it.disabled ||
                    (it.key === 'district' && (filters.city == null || filters.city.length <= 0))
                  }
                >
                  {collection != null ? collection.map(optionList) : []}
                </Select>
              </FormItem>
            );
            break;
          case 'input':
            input = (
              <FormItem label={it.label}>
                <Input
                  addonBefore={it.addonBefore}
                  addonAfter={it.addonAfter}
                  onChange={this.handleFilter(it.key)}
                  value={it.formatter(filters[it.key])}
                />
              </FormItem>
            );
            break;
          case 'range':
            input = (
              <div>
                {getFilterRange(filters, this.key, this.dQtt, it.labelBefore, it.labelAfter)}
                <Slider
                  range
                  defaultValue={this.dQtt}
                  min={it.min}
                  max={it.max}
                  step={it.step}
                  onAfterChange={this.handleFilter(this.key)}
                  tipFormatter={it.tipFormatter}
                />
              </div>
            );
            break;
          case 'switch':
            input = (
              <div>
                <Switch defaultChecked={filters[it.key]} onChange={this.handleFilter(it.key)} />
                {it.label}
              </div>
            );
            break;
          default:
            input = null;
        }
        let node = (
          <Col span={it.span} key={idx}>
            {input}
          </Col>
        );

        acc.countSpan += it.span;
        acc.currentRow.push(node);
        if (acc.countSpan % 24 === 0 || acc.countSpan % 24 >= 18) {
          acc.rows.push(
            <Row gutter={40} key={`row${idx}`}>
              {acc.currentRow}
            </Row>
          );
          acc.currentRow = [];
        }
        return acc;
      },
      { rows: [], currentRow: [], countSpan: 0 }
    );

    return (
      <div className="box box-default mb-4">
        <div className="box-header p-4">
          <Input.Search
            placeholder={searchPlaceholder}
            onSearch={this.handleSearchTerm}
            value={this.state.search}
            onChange={this.onTermChange}
            enterButton
          />
        </div>
        <div className="box-divider mx-0" />
        <div className="box-body" style={{ padding: '0px' }}>
          <Collapse bordered={false}>
            <Collapse.Panel header="Opções Avançadas" key="1" style={customPanelStyle}>
              <Form className="ant-advanced-search-form">
                {fields.rows}
                <Row>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <Button onClick={this.handleReset}>Limpar</Button>
                    <Button
                      style={{ marginLeft: 8 }}
                      type="primary"
                      onClick={() => {
                        this.props.setFilters(datakey, filters, 1, pageSize);
                      }}
                    >
                      Buscar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Collapse.Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}

export const AdvancedFilter = connect(
  state => ({}),
  { setFilters, resetFilters }
)(AdvancedFilterBase);
