import React from 'react';
import Utils from 'utils';
import { AuctionCardHeader } from './AuctionCardHeader';
import { Button, Row, Col } from 'antd';
import { Link } from 'react-router-dom';

export const AuctionCard = ({ auction = {}, onFavorite, onCompare }) => {
  return (
    <div className="mb-4">
      <div className="item-card card__horizontal">
        <a href={auction.link_url} target="_blank" rel="nofollow">
          <div
            className={
              'card__image' +
              (auction.status && auction.status.toLowerCase() === 'em breve'
                ? ' ribbon-container ribbon-danger'
                : '')
            }
          >
            {auction.status &&
              auction.status.toLowerCase() === 'em breve' && (
                <div className="ribbon-wrapper">
                  <div className="ribbon">Em Breve</div>
                </div>
              )}
            <img
              src={auction.image}
              onError={e => {
                e.target.src = require('assets/images/no-image-lote.png');
              }}
              style={{ minWidth: '100%', pointerEvents: 'none' }}
              alt=""
            />
          </div>
        </a>
        <div
          className="card__body card-white"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <AuctionCardHeader auction={auction} onFavorite={onFavorite} />
            <div className="divider divider-solid my-2" />
            <Row>
              {auction.comitent && (
                <Col span={12}>
                  <b>Comitente:</b>
                  &nbsp; <span>{auction.comitent}</span>
                </Col>
              )}
              {auction.origin && (
                <Col span={12}>
                  <b>Leiloeiro:</b>
                  &nbsp; <span>{Utils.normalize('comitent', [auction.origin])[0]}</span>
                </Col>
              )}
              {auction.property_type && (
                <Col span={12}>
                  <b>Tipo:</b>
                  &nbsp; <span>{auction.property_type}</span>
                </Col>
              )}
              {auction.state && (
                <Col span={12}>
                  <b>Estado:</b>
                  &nbsp; <span>{auction.state}</span>
                </Col>
              )}
              {auction.city && (
                <Col span={12}>
                  <b>Cidade:</b>
                  &nbsp; <span>{auction.city}</span>
                </Col>
              )}
              {auction.district && (
                <Col span={12}>
                  <b>Bairro:</b>
                  &nbsp; <span>{auction.district}</span>
                </Col>
              )}
              {auction.address && (
                <Col span={12}>
                  <b>Endereço:</b>
                  &nbsp; <span>{auction.address}</span>
                </Col>
              )}
              {auction.area && (
                <Col span={12}>
                  <b>Area:</b>
                  &nbsp; <span>{auction.area.toString() + auction.area_type}</span>
                </Col>
              )}
            </Row>
          </div>
          <div>
            <a href={auction.link_url} target="_blank" rel="nofollow">
              <Button icon="search" className="text-uppercase">
                Ver leilão
              </Button>
            </a>
            <Link to={`/app/comparative/auction/${auction.id}`} target="_blank">
              <Button icon="environment" className="ml-2 text-uppercase">
                Imóveis na mesma região
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
