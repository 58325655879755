import React from 'react';
import Utils from 'utils';
import { Row, Pagination, Icon } from 'antd';
import { changePage } from '../../../actions/filterPagActions';
import { connect } from 'react-redux';

class PaginationContainerBase extends React.Component {
  getFiltersTitle() {
    const { filters = {} } = this.props;
    let title = [];
    if (filters.search) {
      title.push(
        <span>
          Filtrando pela palavra-chave: <b>{filters.search}</b>
        </span>
      );
    }
    return title.map((it, i) => <p key={i}>{it}</p>);
  }

  getExcelUrl() {
    const { filters = {}, excelUrl } = this.props;
    let filterParams = Object.keys(filters)
      .filter(it => filters[it] != null)
      .map(it => it + '=' + filters[it])
      .join('&');
    return `${Utils.baseURL}/api/${excelUrl}?${filterParams}&token=${Utils.token}`;
  }

  changePageHandler = (page, pageSize) => {
    window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
    this.props.changePage(this.props.datakey, page, pageSize);
  };

  render() {
    const { page, pageSize, total, children } = this.props;
    const pag = (
      <Pagination
        current={page}
        defaultCurrent={1}
        pageSize={pageSize}
        style={{ display: 'inline-block', float: 'right' }}
        total={total}
        onChange={this.changePageHandler}
      />
    );
    return (
      <React.Fragment>
        <Row>
          <div className="pagination-summary">{`Mostrando ${pageSize * (page - 1)}-${pageSize *
            page} resultados de ${total}`}</div>
          {this.getFiltersTitle()}
          <a
            href={this.getExcelUrl()}
            title="Download da pesquisa de corretores"
            className="ant-btn excel-export-link"
          >
            <Icon type="download" />
          </a>
          {pag}
        </Row>
        <Row>{children}</Row>
        <Row>{pag}</Row>
      </React.Fragment>
    );
  }
}

export const PaginationContainer = connect(
  state => ({ pages: state.pages }),
  { changePage }
)(PaginationContainerBase);
