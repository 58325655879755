import * as types from '../constants/actionTypes';
import Utils from 'utils';

export function getAuctions(filters, pageSize, page, key='auctions', onlyFavorites=false) {
  return async dispatch => {
    try {
      dispatch({ type: types.GET_AUCTIONS_PENDING });
      let res = await Utils.request({
        url: '/api/auctions',
        method: 'POST',
        data: {
          page_size: pageSize,
          page: page,
          filters: filters,
          only_favorites: onlyFavorites,
        },
      })
      dispatch({ type: types.GET_AUCTIONS_SUCCESS, key, data: res.data});
    } catch (e) {
      console.error(e)
      dispatch({ type: 'GET_AUCTIONS_REJECTED' });
    }
  };
}

export function dashboardChangePage(page, pageSize, key = 'auctions') {
  return async dispatch => {
    dispatch({ type: types.AUCTION_CHANGE_PAGE, key, page, pageSize });
  };
}

export function favoriteAuction(auction) {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_AUCTIONS_PENDING });
    try {
      await Utils.request({
        url: '/api/auctions',
        method: 'POST',
        data: {
          operation: auction.is_favorite ? 'unfavorite_auction' : 'favorite_auction',
          id: auction.id,
        },
      })
      dispatch({ type: types.FAVORITE_AUCTION_SUCCESS, auction, favorite: !auction.is_favorite });
    } catch (e) {
      dispatch({ type: 'FAVORITE_AUCTION_REJECTED' });
    }
  };
}

export function changeOrderBy(order, key = 'auctions') {
  return async dispatch => {
      dispatch({ type: types.AUCTION_CHANGE_ORDER, key, order });
      dispatch({ type: types.AUCTION_CHANGE_PAGE, key, page: 1, pageSize: 10 });
  };
}

export function setFilters(filters, key = 'auctions') {
  return async dispatch => {
      dispatch({ type: types.AUCTION_SET_FILTERS, key, filters });
      dispatch({ type: types.AUCTION_CHANGE_PAGE, key, page: 1, pageSize: 10 });
  };
}

export function resetFilters(key = 'auctions') {
  return async dispatch => {
    dispatch({ type: types.AUCTION_RESET_FILTERS, key });
    dispatch({ type: types.AUCTION_CHANGE_PAGE, key, page: 1, pageSize: 10 });
  };
}

export function getAuctionDomain() {
  return async dispatch => {
    try {
      dispatch({ type: types.GET_AUCTIONS_PENDING });
      let res = await Utils.request({
        url: '/api/auction-domain',
        method: 'POST',
      })
      dispatch({ type: types.AUCTION_DOMAIN_SUCCESS, data: res.data});
    } catch (e) {
      console.error(e)
      dispatch({ type: types.AUCTION_DOMAIN_REJECTED, error: e });
    }
  };
}
