import React from 'react';
import Utils from 'utils';
import { Input, Form, Row, Select, Col, Button } from 'antd';
const Option = Select.Option;
const FormItem = Form.Item;

const optionList = it =>
  it ? (
    <Option key={it} value={it}>
      {it}
    </Option>
  ) : null;
const defaultFilterOption = (input, option) =>
  option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;

const handleFilter = (onChange, filters, addresses) => key => value => {
  let temp;
  switch (key) {
    case 'city':
      filters.city = value;
      if (
        addresses.findIndex(item => {
          return item.city === value && filters.district === item.district;
        }) === -1
      ) {
        filters.district = '';
      }
      break;
    case 'state':
      filters.state = value;
      if (
        addresses.findIndex(item => {
          return value.find(s => s === item.state) && filters.city === item.city;
        }) === -1
      ) {
        filters.city = '';
      }
      if (
        addresses.findIndex(item => {
          return value.find(s => s === item.city) && filters.district === item.district;
        }) === -1
      ) {
        filters.district = '';
      }
      break;
    case 'minValue':
      temp = value.target.value;
      temp = temp.replace(/[^0-9]/g, '');
      filters.minValue = Number(temp);
      break;
    case 'maxValue':
      temp = value.target.value;
      temp = temp.replace(/[^0-9]/g, '');
      filters.maxValue = Number(temp);
      break;
    default:
      filters[key] = value;
  }
  onChange(filters);
};

export const AuctionAdvancedFilter = ({
  addresses = [],
  filters = {},
  origins,
  statuses,
  property_types,
  comitents,
  onSearch,
  onReset,
  onChange,
}) => {
  let cities = [];
  let states = [];
  let districts = [];

  addresses.forEach(it => {
    states.push(it.state);
    if (
      filters.state === undefined ||
      !filters.state.length ||
      filters.state.find(
        s => it.state && Utils.normalizaString(it.state) === Utils.normalizaString(s)
      )
    ) {
      cities.push(it.city);
    }
    if (
      filters.city === undefined ||
      !filters.city.length ||
      filters.city.find(s => it.city && Utils.normalizaString(it.city) === Utils.normalizaString(s))
    ) {
      districts.push(it.district);
    }
  });

  states = Utils.normalize('state', states);
  cities = Utils.normalize('city', cities);
  districts = Utils.normalize('district', districts);

  const onHandleFilter = handleFilter(onChange, filters, addresses);

  return (
    <Form className="ant-advanced-search-form">
      <Row gutter={40}>
        <Col span={6}>
          <FormItem label="Leiloeiro">
            <Select
              allowClear
              showSearch
              mode="tags"
              style={{ width: '100%' }}
              value={filters.origin || []}
              placeholder="Leiloeiro"
              optionFilterProp="children"
              onChange={onHandleFilter('origin')}
              filterOption={defaultFilterOption}
            >
              {origins.map(optionList)}
            </Select>
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem label="Situação">
            <Select
              allowClear
              showSearch
              mode="tags"
              style={{ width: '100%' }}
              value={filters.status || []}
              placeholder="Situação"
              optionFilterProp="children"
              onChange={onHandleFilter('status')}
              filterOption={defaultFilterOption}
            >
              {statuses.map(optionList)}
            </Select>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Tipo">
            <Select
              allowClear
              showSearch
              mode="tags"
              style={{ width: '100%', maxWidth: 500 }}
              value={filters.property_type || []}
              placeholder="Tipo de Imóvel"
              optionFilterProp="children"
              onChange={onHandleFilter('property_type')}
              filterOption={defaultFilterOption}
            >
              {property_types.map(optionList)}
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={40}>
        <Col span={6}>
          <FormItem label="Estado">
            <Select
              allowClear
              showSearch
              mode="tags"
              style={{ width: '100%' }}
              value={filters.state || []}
              placeholder="Estado"
              optionFilterProp="children"
              onChange={onHandleFilter('state')}
              filterOption={defaultFilterOption}
            >
              {states.map(optionList)}
            </Select>
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem label="Cidade">
            <Select
              allowClear
              showSearch
              mode="tags"
              style={{ width: '100%' }}
              value={filters.city || []}
              placeholder="Cidade"
              optionFilterProp="children"
              onChange={onHandleFilter('city')}
              filterOption={defaultFilterOption}
            >
              {cities.map(optionList)}
            </Select>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Bairro">
            <Select
              allowClear
              showSearch
              mode="tags"
              style={{ width: '100%', maxWidth: 500 }}
              value={filters.district || []}
              placeholder="Bairro"
              optionFilterProp="children"
              onChange={onHandleFilter('district')}
              filterOption={defaultFilterOption}
            >
              {districts.map(optionList)}
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={40}>
        <Col span={6}>
          <FormItem label="Valor Mínimo">
            <Input
              addonBefore="R$"
              onChange={onHandleFilter('minValue')}
              value={Utils.formatters.currencyNumber(filters.minValue)}
            />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem label="Valor Máximo">
            <Input
              addonBefore="R$"
              onChange={onHandleFilter('maxValue')}
              value={Utils.formatters.currencyNumber(filters.maxValue)}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Comitente">
            <Select
              allowClear
              showSearch
              mode="tags"
              style={{ width: '100%', maxWidth: 500 }}
              value={filters.comitent || []}
              placeholder="Comitente"
              optionFilterProp="children"
              onChange={onHandleFilter('comitent')}
              filterOption={defaultFilterOption}
            >
              {comitents.map(optionList)}
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button onClick={onReset}>Limpar</Button>
          <Button
            style={{ marginLeft: 8 }}
            type="primary"
            onClick={() => {
              onSearch(filters);
            }}
          >
            Buscar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
