import * as types from '../constants/actionTypes';

export function setFilters(key, filters, page = 1, pageSize = 10) {
  return async dispatch => {
    dispatch({ type: types.SET_FILTERS, key, filters });
    dispatch({ type: types.CHANGE_PAGE, key, page, pageSize });
  };
}

export function resetFilters(key, page = 1, pageSize = 10) {
  return async dispatch => {
    dispatch({ type: types.RESET_FILTERS, key });
    dispatch({ type: types.CHANGE_PAGE, key, page, pageSize });
  };
}

export function changePage(key, page, pageSize) {
  return async dispatch => {
    dispatch({ type: types.CHANGE_PAGE, key, page, pageSize });
  };
}
